import { Link } from "gatsby"
import React from "react"
import Button from "../common/Button"
import Container from "../virtual-lab/Container"

const DATA = {
  title: "Clinical-grade accuracy",
  descriptions: [
    "PNOĒ has been independently validated to provide the same accuracy and information as breath analysis systems found in hospitals and labs. The validation study was conducted by researchers from Rutgers University, Georgetown University, and the Veteran Affairs hospital in Maryland.",
  ],
  button: {
    name: "Download our validation study",
    href: "https://www.frontiersin.org/articles/10.3389/fspor.2019.00024/full",
  },
}

export default function Accuracy() {
  return (
    <Container
      py="10rem"
      bleed="both"
      css={`
        flex-direction: column;
        text-align: center;
      `}
    >
      <Container.Title>{DATA.title}</Container.Title>
      <Container.Description width="65rem">
        {DATA.descriptions[0]}
      </Container.Description>
      <Button as="a" href={DATA.button.href}>
        {DATA.button.name}
      </Button>
    </Container>
  )
}
