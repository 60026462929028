import React from "react"
import Container from "../virtual-lab/Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "What is metabolism, and why Breath Analysis matters?",
  descriptions: [
    "Metabolism is everything your body does to turn nutrients (i.e., fats, carbs, protein) into the energy it needs to survive and move. Oxygen is the critical element in this process. That's why your metabolism uses your heart, lungs, and blood to transport oxygen into your cells, where it's used to convert nutrients into energy.",
    "10,000+ studies have established Breath Analysis as the most reliable method for measuring how effectively oxygen flows through these systems, identifying the ones that pose a problem, and determining the nutrition, training, and recovery that works for your biology.",
  ],
  button: {
    name: "Discover our science in detail",
    to: "/the-science",
  },
}

const Title = props => (
  <Container.Title width="43rem" {...props}>
    {DATA.title}
  </Container.Title>
)

const DescriptionOne = props => (
  <Container.Description width="41rem" {...props}>
    {DATA.descriptions[0]}
  </Container.Description>
)

const DescriptionTwo = props => (
  <Container.Description width="41rem" {...props}>
    {DATA.descriptions[1]}
  </Container.Description>
)

const CTA = props => (
  <Button as={Link} to={DATA.button.to} {...props}>
    {DATA.button.name}
  </Button>
)

const Image = props => (
  <Container.Image align="center" {...props}>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/why-pnoe/analysis-matters.png"
      alt="phone"
      width={336}
    />
  </Container.Image>
)

export default function AnalysisMatters() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Title />
        <DescriptionOne />
        <DescriptionTwo />
        <CTA />
      </div>
      <Image />
    </Container>
  )
}

AnalysisMatters.Mobile = () => (
  <Container bleed="both">
    <Title />
    <DescriptionOne />
    <Image />
    <DescriptionTwo mt={24} />
    <CTA />
  </Container>
)
