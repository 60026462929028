import React from "react"
import Container from "../virtual-lab/Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "A lab on your clients' wrist.",
  descriptions: [
    "Every fitness tracker uses an estimation of the individual's metabolic profile to provide fitness insights. However, its generic algorithms provide low accuracy and limited information. Our algorithm calibrates your clients' wearable to their metabolic profile measured by the PNOĒ test. It then benchmarks them against a pool of 50,000+ metabolic profiles to track 12 health metrics, including heart fitness, lung fitness, metabolic efficiency, and fat burning capacity 24/7 just by using their calibrated wearable data.",
  ],
  button: {
    name: "Dive into the science",
    to: "/the-science",
  },
}

const Title = props => (
  <Container.Title width="43rem" {...props}>
    {DATA.title}
  </Container.Title>
)

const Description = props => (
  <Container.Description width="41rem" {...props}>
    {DATA.descriptions[0]}
  </Container.Description>
)

const CTA = props => (
  <Button as={Link} to={DATA.button.to} {...props}>
    {DATA.button.name}
  </Button>
)

const Image = props => (
  <Container.Image align="center" {...props}>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/why-pnoe/wrist-lab.png"
      alt="phone"
      width={335}
    />
  </Container.Image>
)

export default function WristLab() {
  return (
    <Container bleed="both" py="10rem">
      <Image />
      <div>
        <Title />
        <Description />
        <CTA />
      </div>
    </Container>
  )
}

WristLab.Mobile = () => (
  <Container bleed="both">
    <Title />
    <Image />
    <Description mt={24} />
    <CTA />
  </Container>
)
