import React from "react"
import Container from "../virtual-lab/Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "The most advanced monitoring platform.",
  descriptions: [
    "The PNOĒ Precision app in your clients' mobile device syncs automatically with the PNOĒ platform, allowing you to monitor them every step of the way. You can use it through any computer or tablet to monitor your clients' health, nutrition, training, physical recovery, and psychosomatic stress levels.",
  ],
  button: {
    name: "Book a demo",
    to: "/payment",
  },
}

const Title = props => (
  <Container.Title width="43rem" {...props}>
    {DATA.title}
  </Container.Title>
)

const Description = props => (
  <Container.Description width="41rem" {...props}>
    {DATA.descriptions[0]}
  </Container.Description>
)

const CTA = props => (
  <Button as={Link} to={DATA.button.to} {...props}>
    {DATA.button.name}
  </Button>
)

const Image = props => (
  <Container.Image align="center" {...props}>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/why-pnoe/monitoring-platform.png"
      alt="phone"
      width={490}
    />
  </Container.Image>
)

export default function MonitoringPlatform() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Title />
        <Description />
        <CTA />
      </div>
      <Image />
    </Container>
  )
}

MonitoringPlatform.Mobile = () => (
  <Container bleed="both">
    <Title />
    <Image />
    <Description mt={24} />
    <CTA />
  </Container>
)
