import React from "react"
import Container from "../virtual-lab/Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "The most complete app for your clients.",
  descriptions: [
    "We believe that finding the problem and pointing to the solution is not enough. Sustainable results require guidance every step of the way. That's why we built PNOĒ Precision, the first AI-powered app that allows you to track and guide your clients 24/7 on all four pillars of wellness: Activity, Nutrition, Physical Recovery, and Mental Readiness. All calibrated to their metabolism.",
  ],
  button: {
    name: "Learn more",
    to: "/the-science",
  },
}

const Title = props => (
  <Container.Title width="43rem" {...props}>
    {DATA.title}
  </Container.Title>
)

const Description = props => (
  <Container.Description width="41rem" {...props}>
    {DATA.descriptions[0]}
  </Container.Description>
)

const CTA = props => (
  <Button as={Link} to={DATA.button.to} {...props}>
    {DATA.button.name}
  </Button>
)

const Image = props => (
  <Container.Image align="center" {...props}>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/why-pnoe/client-app.png"
      alt="phone"
      width={505}
    />
  </Container.Image>
)

export default function ClientApp() {
  return (
    <Container bleed="both">
      <div>
        <Title />
        <Description />
        <CTA />
      </div>
      <Image />
    </Container>
  )
}

ClientApp.Mobile = () => (
  <Container bleed="both">
    <Title mb={0} />
    <Image />
    <Description mt={24} />
    <CTA />
  </Container>
)
