import React from "react"
import Container from "../virtual-lab/Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "Everything your practice needs in one place.",
  descriptions: [
    "PNOĒ gives you the tools to track your clients with clinical precision and provide them the most personalized nutrition, training, and recovery program. Wherever they are.",
  ],
  button: {
    name: "Book a demo",
    to: "/payment",
  },
}

const Title = props => (
  <Container.Title width="43rem" {...props}>
    {DATA.title}
  </Container.Title>
)
const Description = props => (
  <Container.Description width="41rem" {...props}>
    {DATA.descriptions[0]}
  </Container.Description>
)

const CTA = props => (
  <Button as={Link} to={DATA.button.to} {...props}>
    {DATA.button.name}
  </Button>
)

const Image = props => (
  <Container.Image align="center" {...props}>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/why-pnoe/hero.png"
      alt="phone"
      width={466}
    />
  </Container.Image>
)

export default function Hero() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Title />
        <Description />
        <CTA />
      </div>
      <Image />
    </Container>
  )
}

Hero.Mobile = () => (
  <Container bleed="both">
    <Title />
    <Image />
    <Description mt={24} />
    <CTA />
  </Container>
)
