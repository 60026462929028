import React from "react"
import Container from "../virtual-lab/Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "A lab delivered to your clients' homes.",
  descriptions: [
    "The PNOĒ test provides a much deeper look into your clients' physiology than traditional lab-based VO2max and metabolic tests, thanks to our AI-powered analysis. Trained on 50,000+ metabolic profiles, our algorithms dissect the human metabolism through 10 biomarkers and identify what's keeping your clients from achieving their health goals. Then, based on best practices drawn from 1000+ peer-reviewed studies, we provide the most personalized nutrition, training, and recovery insights science can offer. All this from the comfort of their home.",
  ],
  button: {
    name: "Check out how it works",
    to: "/the-science",
  },
}

const Title = props => (
  <Container.Title width="43rem" {...props}>
    {DATA.title}
  </Container.Title>
)

const Description = props => (
  <Container.Description width="41rem" {...props}>
    {DATA.descriptions[0]}
  </Container.Description>
)

const CTA = props => (
  <Button as={Link} to={DATA.button.to} {...props}>
    {DATA.button.name}
  </Button>
)

export default function HomeLab() {
  return (
    <Container bleed="right">
      <div>
        <Title />
        <Description />
        <CTA />
      </div>
      <Container.Image align="right">
        <StaticImage
          placeholder="blurred"
          src="../../assets/images/why-pnoe/home-lab.png"
          alt="phone"
          width={705}
        />
      </Container.Image>
    </Container>
  )
}

HomeLab.Mobile = () => (
  <Container bleed="both">
    <Title />
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/why-pnoe/home-lab-mobile.png"
      alt="home lab"
    />
    <Description mt={24} />
    <CTA />
  </Container>
)
