import React from "react"
import Layout from "../components/common/Layout"
import SEO from "../components/common/SEO"
import Divider from "../components/common/Divider"
import { DEVICES } from "../config"
import useWindowSize from "../hooks/useWindowSize"
import Hero from "../components/why-pnoe/Hero"
import TheProblem from "../components/why-pnoe/TheProblem"
import AnalysisMatters from "../components/why-pnoe/AnalysisMatters"
import HomeLab from "../components/why-pnoe/HomeLab"
import ClientApp from "../components/why-pnoe/ClientApp"
import WristLab from "../components/why-pnoe/WristLab"
import MonitoringPlatform from "../components/why-pnoe/MonitoringPlatform"
import Accuracy from "../components/why-pnoe/Accuracy"

export default function WhyPnoePage() {
  const { width } = useWindowSize()

  return (
    <Layout>
      <SEO title="Why PNOĒ" />
      <Divider.Light />
      {width < DEVICES.desktop ? (
        <>
          <Hero.Mobile />
          <Divider.Light />
          <TheProblem />
          <Divider.Light />
          <AnalysisMatters.Mobile />
          <Divider.Light />
          <HomeLab.Mobile />
          <Divider.Light />
          <ClientApp.Mobile />
          <Divider.Light />
          <WristLab.Mobile />
          <Divider.Light />
          <MonitoringPlatform.Mobile />
          <Divider.Light />
          <Accuracy />
        </>
      ) : (
        <>
          <Hero />
          <Divider.Light />
          <TheProblem />
          <Divider.Light />
          <AnalysisMatters />
          <Divider.Light />
          <HomeLab />
          <Divider.Light />
          <ClientApp />
          <Divider.Light />
          <WristLab />
          <Divider.Light />
          <MonitoringPlatform />
          <Divider.Light />
          <Accuracy />
        </>
      )}
    </Layout>
  )
}
