import { Link } from "gatsby"
import React from "react"
import Button from "../common/Button"
import Container from "../virtual-lab/Container"

const DATA = {
  title: "The Problem",
  descriptions: [
    "90% of fitness plans fail because they are not personalized to the individual's metabolism, NOT a lack of willpower. However, metabolism isn't one thing. It's a complex mechanism that involves your heart, lungs, blood circulation, hormones, and cells.",
    "Whether you are training for a race or trying to lose weight, discovering how the pieces of your metabolism work and fixing the broken ones is the only way to success. PNOĒ is the first clinical-grade metabolic analysis program that makes this possible.",
  ],
}

export default function TheProblem() {
  return (
    <Container
      py="10rem"
      bleed="both"
      css={`
        flex-direction: column;
        text-align: center;
      `}
    >
      <Container.Title mb={32}>{DATA.title}</Container.Title>
      <Container.Description width="86rem">
        {DATA.descriptions[0]}
      </Container.Description>
      <Container.Description width="86rem">
        {DATA.descriptions[1]}
      </Container.Description>
    </Container>
  )
}
